'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var cookiemgr = require('../cookiemgr');

/**
 * craete modal element
 */
function getStatusModalHtmlElement() {
    if ($('#subscribeStatus').length !== 0) {
        $('#subscribeStatus').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="subscribeStatus" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog status-dialog modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-body text-center">'
        + '<h2 class="dashboard-heading mb-4"></h2>'
        + '<p class="modal-content-text medium mb-4"></p>'
        + '<i class="icon-logo-uec"></i>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * display status modal
 * @param {Object} data subscribed data
 * @param {boolean} success success modal
 */
function displayStatusModal(data, success) {
    var $emailInput = $('input[name=mcEmailSignup]');
    // Init modal
    getStatusModalHtmlElement();
    $('#subscribeStatus .modal-body .modal-content-text').empty();
    $('#subscribeStatus .modal-body .dashboard-heading').html(success ? 'Success' : 'Error!');
    $('#subscribeStatus .modal-body .modal-content-text').html(data.msg);
    $('#subscribeStatus').modal('show');

    if (!success) {
        $emailInput.addClass('is-invalid');
        $emailInput.val($emailInput.data('error-email'));
    }

    setTimeout(function () {
        $('#subscribeStatus, #modal-subscription').modal('hide');
    }, 4500);
}

/**
 * render form
 * @param {*} formData data
 * @param {string} redirect should redirect to homepage
 */
function renderForm(formData, redirect) {
    var $optInModal = $('#mailChimpOptInModal');
    var $agreeButton = $optInModal.find('.data-agreement');
    var redirectHome = redirect;
    if ($optInModal.length && $agreeButton.length) {
        $optInModal.modal('show');
        $agreeButton.attr('disabled', false);
        $agreeButton.unbind().on('click', function (e) {
            e.preventDefault();
            var url = $(this).data('href');
            $(this).attr('disabled', true);
            $.ajax({
                url: url,
                type: 'post',
                data: formData,
                success: function (data) {
                    $('body').trigger('gtm:newsletter:signup', { handler: 'signup' });
                    $optInModal.modal('hide');
                    displayStatusModal(data, data.success);
                    cookiemgr.setCookie('closed-subscribe', true, null);
                    if (redirect && data.success) {
                        setTimeout(function () {
                            window.location.href = redirectHome;
                        }, 3600);
                    }
                },
                error: function (err) {
                    $optInModal.modal('hide');
                    displayStatusModal(err, false);
                    cookiemgr.setCookie('closed-subscribe', false, null);
                }
            });
        });
    }
}

/**
     * @description Newsletter form label functionality to show/hide input label
     * * @returns {void}
     */
function initInlineLabels() {
    var $inputs = $('.newsletter-form input.form-control, .footer-email-signup input.form-control');

    $inputs.focus(function () {
        $(this).parent().find('.form-control-label').addClass('label-active');
        $('.icon-email-send').css('opacity', '1');
    });

    $inputs.focusout(function () {
        if ($(this).val() === '') {
            $(this).parent().find('.form-control-label').removeClass('label-active');
        }
        $('.icon-email-send').css('opacity', '0');
    });

    // make labels visible in inputs if the area is autofilled
    $($inputs).each(function () {
        var $this = $(this),
            $inputType = $this.attr('type');
        // don't apply label-active class if there is a hidden input with a value on it
        if ($inputType === 'text') {
            if ($this.val() !== '') {
                $this.parent().find('.form-control-label').addClass('label-active');
            }
        }
    });

    $(document).on('change keypress', '.landing-newsletter-form input', function () {
        if ($(this).hasClass('is-invalid')) {
            $(this).removeClass('is-invalid');
        }
    });
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message signup-messages"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '" role="alert">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').first().fadeOut(function () {
            this.remove();
        });
        button.removeAttr('disabled');
    }, globalConfig.toastTimeout);
}

/**
 * Render SMS Opt-in
 */
function showSmsOptIn() {
    let $emailContent = $('.text-center.email-tc'),
        $smsContent = $('.text-center.sms-tc');

    $emailContent.fadeOut(500, () => {
        $smsContent.fadeIn(500);
    });
}

/**
 * Rendering subscrition popup
 */
function showSubscriptionModal() {
    $(document).on('click', '.mc-subscribe-email-landing', function (e) {
        e.preventDefault();

        let $email = $('input[name=emailId]'),
            that = this,
            $this = $('.keep-current-email'),
            $form = $(this).closest('form'),
            serializedData = $form.serialize(),
            url = this.dataset.subscribeUrl;

        if ($email.val().length > 0) {
            $this.addClass('is-invalid');
        }

        $this.removeClass('is-invalid');

        if (!$form[0].checkValidity()) {
            return;
        }

        if ($email.length) {
            $.ajax({
                url: url,
                method: 'post',
                data: serializedData,
                success: function (data) {
                    let container = document.querySelector('.email-tc'),
                        messageCntr = document.createElement('div'),
                        messageP = document.createElement('p');

                    messageCntr.classList.add('message-center');
                    messageP.textContent = data.msg;
                    messageCntr.append(messageP);

                    if (data.success) {
                        $(that).fadeOut(500, () =>{
                            that.parentNode.removeChild(that);
                            container.append(messageCntr);

                            messageCntr.classList.add('show');

                            setTimeout(showSmsOptIn, 2500);
                        });
                    } else {
                        renderForm(serializedData);
                    }
                }
            });
        }
    });

    $(document).on('click', '#sms-optin-submit', function () {
        let $that = $(this),
            phoneInput = document.getElementById('sms-number'),
            validity = null;

        if (phoneInput) {
            validity = phoneInput.validity;

            if (validity.patternMismatch === true) {
                phoneInput.setCustomValidity(window.Resources.MOBILE_TEL_PATTERN_MISMATCH);
            } else {
                phoneInput.setCustomValidity('');
            }
        }

        if (phoneInput.reportValidity()) {
            $.ajax({
                url: window.Urls.attentiveSubscribe,
                method: 'post',
                data: { smsNumber: phoneInput.value }
            }).done((data) => {
                if (!data.error && data.code === 202) {
                    let smsContent = document.querySelector('.sms-content'),
                        messageCntr = document.createElement('div'),
                        messageP = document.createElement('p');


                    messageCntr.classList.add('message-center');
                    messageP.innerHTML = data.message;
                    messageCntr.append(messageP);

                    $that.fadeOut(500, () => {
                        $that.remove();
                        smsContent.append(messageCntr);

                        messageCntr.classList.add('show');

                        setTimeout(() => {
                            $('#modal-subscription').modal('hide');
                        }, 5000);
                    });
                } else {
                    phoneInput.setCustomValidity(data.error.errorText);
                    phoneInput.reportValidity();
                }
            });
        }
    });

    // Bind checkbox invalid messages
    $(document).on('change keypress', '.landing-newsletter-form input', function () {
        if ($(this).hasClass('is-invalid')) {
            $(this).removeClass('is-invalid');
        }
    });

    $('#modal-subscription').modal('show');
}


module.exports = function () {
    $(document).scroll(function () {
        if ($(this).scrollTop() > 330) {
            $('.back-to-top-site-wide').removeClass('d-none');
        } else {
            $('.back-to-top-site-wide').addClass('d-none');
        }

        if ($(this).scrollTop() > 1) {
            $('.modal-background').addClass('scrolling');
        } else {
            $('.modal-background').removeClass('scrolling');
        }
    });

    $('.back-to-top-site-wide').click(function () {
        scrollAnimate();
    });

    $('.newsletter-confirmation-agree').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                $('#newsletter-confirmation-modal').modal('hide');
                displayMessage(data, button);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('input[name=mcEmailSignup]').keypress(function (e) {
        var code = e.keyCode || e.which;
        if (code === 13) {
            e.preventDefault();
            var $email = $('input[name=mcEmailSignup]');
            $email.removeClass('is-invalid');
            if ($email.val().length > 0) {
                var emailId = $email.val();
                $.spinner().start();
                renderForm({ emailId: emailId });
                $.spinner().stop();
            } else {
                $email.addClass('is-invalid');
            }
        }
    });

    $('input[name=mcEmailSignup], input[name=mcEmailSignupContact]').on('focus', function () {
        var $this = $(this);
        if ($this.hasClass('is-invalid')) {
            $this.removeClass('is-invalid');
            $this.val('');
        }
    });

    $('.mc-subscribe-email').on('click', function (e) {
        e.preventDefault();
        var $email = $('input[name=mcEmailSignup]');
        $email.removeClass('is-invalid');
        if ($email.val().length > 0) {
            var emailId = $email.val();
            $.spinner().start();
            renderForm({ emailId: emailId });
            $.spinner().stop();
        } else {
            $email.addClass('is-invalid');
        }
    });

    var closedSubscribe = cookiemgr.getCookie('closed-subscribe');
    var browsingSince = cookiemgr.getCookie('browsing-since');
    var currentTime = new Date().getTime();

    if (browsingSince !== null) {
        if ((currentTime - browsingSince) > 120000 && !closedSubscribe) {
            if ($('#modal-subscription').length) {
                showSubscriptionModal();
            }
        }
    } else {
        cookiemgr.setCookie('browsing-since', currentTime, null);
    }

    $('#modal-subscription').on('hidden.bs.modal', function () {
        cookiemgr.setCookie('closed-subscribe', true, null);
    });

    initInlineLabels();
};
