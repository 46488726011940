'use strict';
var globalConfig = require('../config/global');
var headerBanner = require('./header-banner');
var root = document.documentElement;
var isWebflowPage = document.querySelectorAll('.webflow-page').length > 0;
var webflowOffset = require('./webflowOffset');
var temporaryBannerExists = document.querySelectorAll('#temporary-banner').length > 0;

/**
 * Offset site content per fluid header height on resize
 */
function mainContentResize() {
    var headerHeight = $('header').height() + 'px';
    root.style.setProperty('--maincontent-top', headerHeight);
    root.style.setProperty('--modal-background-top', headerHeight);
    root.style.setProperty('--refinements-sticky-top', headerHeight);
    root.style.setProperty('--refinements-sticky-top', headerHeight);

    if (isWebflowPage) {
        webflowOffset.initOffsetWebflowContent();

        if (temporaryBannerExists) {
            headerBanner.initResizeBannerWebflow();
        }
    }

    if (!isWebflowPage) {
        if (temporaryBannerExists) {
            $('#maincontent, .modal-background, .container.search-results .refinements-sticky, .contact-form-anchor-container, .contact-modal-anchor-container').addClass('resizing');
            var processedHeaderHeight = $('.header.container').height() + $('.header-banner').height();
            root.style.setProperty(
                '--banner-maincontent-top', processedHeaderHeight + 'px'
            );
            root.style.setProperty(
                '--banner-modal-top', processedHeaderHeight + 'px'
            );
            root.style.setProperty(
                '--banner-refinements-top', processedHeaderHeight + 'px'
            );

            if (document.querySelectorAll('.page-clearheader').length > 0) {
                var processedBannerHeight = $('.header-banner').height() + 'px';
                root.style.setProperty(
                    '--banner-clearheader-top', processedBannerHeight
                );
            }
        }
    }
}
/**
 * Offset site content per fluid header height
 */
function mainContentOffset() {
    var headerHeight = $('header').height() + 'px';
    root.style.setProperty('--maincontent-top', headerHeight);
    root.style.setProperty('--modal-background-top', headerHeight);
    root.style.setProperty('--refinements-sticky-top', headerHeight);

    if (document.querySelectorAll('.page-clearheader').length > 0) { $('.modal-background').addClass('page-clearheader'); }

    if (isWebflowPage) {
        webflowOffset.webflowBackToTopBtn();

        if ((document.querySelectorAll('.webflow_subnav_top_border').length > 0)) {
            $('header').addClass('webflow-bottom-border');
        }
        webflowOffset.initOffsetWebflowContent();
        if (temporaryBannerExists) {
            setTimeout(function () {
                headerBanner.initTempBannerWebflow();
            }, 200);
        }
    }

    if (temporaryBannerExists && !isWebflowPage) {
        headerBanner.initTemporaryBanner();
    }
}

/**
 * Generates the modal window on the first call.
 * @param {string} modalBodyContent - HTML that should be inserted into the modal
 */
function getModalHtmlElement(modalBodyContent) {
    if ($('#videoModal').length !== 0) {
        $('#videoModal').remove();
    }

    var $content = $(globalConfig.components.global.modal.template);
    $content.find('.modal-body').append(modalBodyContent);

    $('body').append($content);
}

module.exports = {

    /**
     * inits all of the global carousel compoents
     * @see globalConfig - configs/global.js
     */
    initGlobalCarousels: function () {
        var Carousel = require('./carousel');

        globalConfig.components.global.carouselArr.forEach(function (carouselName) {
            var $imageCarousel = new Carousel(carouselName);

            // activate image Carousel
            $imageCarousel.activate();
        });
    },

    /**
     * inits all of the global tooltips
     * @see globalConfig - configs/global.js
     */
    initGlobalTooltips: function () {
        $(globalConfig.components.global.tooltipElement).tooltip();
    },

    initGlobalModalContentLinks: function () {
        globalConfig.components.global.modal.elements.forEach(function (modalEle) {
            var $modalEle = $(modalEle);

            if ($modalEle.length > 0 && $modalEle.hasClass('popup-video') && $modalEle.data('src')) {
                // when the modal is opened autoplay it
                $modalEle.on('click', function () {
                    var videoContent = '<div class="embed-responsive embed-responsive-16by9">'
                        + '<iframe class="embed-responsive-item video-embed" src="" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
                        + '</div>';

                    getModalHtmlElement(videoContent);
                    $('#cmsModal').modal('show');

                    /**
                     * set the video src to autoplay and not to show related video.
                     * Youtube related video is like a box of chocolates... you
                     * never know what you're gonna get
                     */
                    $('.video-embed').attr('src', $(this).data('src') + '?autoplay=1');

                    // stop playing the youtube video when I close the modal
                    $('#cmsModal').on('hide.bs.modal', function () {
                        var $cmsModal = $('#cmsModal');
                        // a poor man's stop video
                        $cmsModal.find('.video-embed').attr('src', '');
                        $cmsModal.remove();
                    });
                });
            }

            if ($modalEle.length > 0 && $modalEle.hasClass('popup-content') && $modalEle.data('src')) {
                // when the modal is opened autoplay it
                $modalEle.on('click', function () {
                    $.ajax({
                        url: $modalEle.data('src'),
                        method: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            getModalHtmlElement(data.content.body);
                            $('#cmsModal').modal('show');

                            // stop playing the youtube video when I close the modal
                            $('#cmsModal').on('hide.bs.modal', function () {
                                $(this).remove();
                            });
                        }
                    });
                });
            }
        });
    },

    /**
     * inits the header scroll watching for cms pages with clear header
     *
     */
    initHeaderScroll: function () {
        var $clearHeader = $('.header-container-clear, .immersive');
        if ($clearHeader.length > 0) {
            var clearHeaderAmt = $('#clear-header-scroll-amount').length ? $('#clear-header-scroll-amount').val() : 0;
            $(window).scroll(function () {
                $clearHeader.toggleClass('scrolled', $(this).scrollTop() > clearHeaderAmt);
            });
        }
    },

    newTabPDFLinks: function () {
        var links = document.getElementsByTagName('a');
        for (var i = 0; i < links.length; i++) {
            var isLinkPDF = links[i].href.slice(links[i].length - 5).toString().toLowerCase().indexOf('.pdf') > -1;
            if (isLinkPDF) {
                links[i].setAttribute('target', '_blank');
            }
        }
    },
    initMainContent: function () {
        mainContentOffset();

        $(window).on('resize', function () {
            mainContentResize();
        });
    }
};
