var cookiemgr = require('../cookiemgr');
var webflowOffset = require('./webflowOffset');
var bannerClasses = $('.header-banner, #maincontent, .modal-background, .container.search-results .refinements-sticky, .webflow_offset_nav, .webflow_offset_menu, .webflow_offset_afternav, .webflow_offset, .webflow_offset_horizontal_slides, .webflow_offset_horizontal, .webflow_offset_horizontal_desktoponly, .webflow_offset_grid, .webflow_offset_hamburger, .webflow_offset_half_sticky, .webflow_offset_half_sticky_desktoponly, .fleet-sticky-half, .offset-text-middle-of-bg, .offset-partial-height-half-sticky-image, .webflow_offset_horizontal_slides-md-lg-xl, .sfcc-100svh-minus-head');
var root = document.documentElement;

/**
 * on closeBanner(), resize webflow content
 */
function resizeBannerClose() {
    bannerClasses.removeClass('bannerslide');
    webflowOffset.initOffsetWebflowContent();
}

/**
 * Close banner, move up below content, store browser cookie
 */
function closeBanner() {
    $('.temp-banner-close-button').on('click', function () {
        var headerOnlyHeight = $('.header.container').height() + 'px';
        root.style.setProperty('--maincontent-top', headerOnlyHeight);
        root.style.setProperty('--modal-background-top', headerOnlyHeight);
        root.style.setProperty('--refinements-sticky-top', headerOnlyHeight);
        resizeBannerClose();

        if ($('#bannerCookieExpire')[0].value === '.1') {
            cookiemgr.setCookie(this.value, true, null);
        } else {
            cookiemgr.setCookie(this.value, true, $('#bannerCookieExpire')[0].value);
        }
    });
}

/**
 * Display banner per page type and hide configurations
 */
function displayHeaderBanner() {
    var showOnAllPages = document.querySelectorAll('.showOnAllPages');

    if (showOnAllPages.length === 1) {
        bannerClasses.addClass('bannerslide');
    } else {
        var eligibleGroups = document.querySelectorAll('.eligibleGroups');
        var pageSelectors;
        var gHomePlus = document.querySelectorAll('#homepage, .homepage, .contact-us.page, #resources, .info-sheets, #legal.pd-dynamiclayout, #patent-pending.pd-dynamiclayout, .patents-page, #copyright.pd-dynamiclayout, #terms-and-conditions.pd-dynamiclayout, #personal-data-and-privacy-policy.pd-dynamiclayout, #tools-for-the-trade, .page-clearheader.heirloom-promise-decorator, .page-clearheader.about-who-we-are-decorator, .page-clearheader.out-and-about-decorator, .page-clearheader.timeline-decorator, #culture-and-career').length > 0; // Home | Contact | Resources | About Sub-Pages(5) | Legal + 4 Sub-Pages | UEcommerce | Info Sheets(3)
        var gPortfoliosPlus = document.querySelectorAll('.portfolio-page-layout, .campaign-page-layout, .category-page, #page-clearheader, .pd-dynamiclayout').length > 0; // Portfolios | Category Pages(Partners | Campaigns | Collections | +more) | PD Landings/PD Misc.
        var gWebflow = document.querySelectorAll('.webflow-page').length > 0; // All Webflow Pages - Includes: The Current Vol. 5 | Fleet | +more
        var gProductPath = document.querySelectorAll('.search-page, .product-detail.product-wrapper, .container.wishlist-container').length > 0; // PLP/Search Return | PDP(Includes Samples) | Favorites
        var gConfigurator = document.querySelectorAll('.configurator-page').length > 0; // Product Configurator
        var gQuotesPricelist = document.querySelectorAll('.account-page.single-quote, .account-page.quote-history-page, .pricelist-page').length > 0; // Quotes History | Single Quote | Price List
        var gOrders = document.querySelectorAll('.account-page.order-history-page, .account-page.single-order-page, .pricelist-page').length > 0; // Orders History | Single Order
        var gCartCheckout = document.querySelectorAll('.page.checkout, .cart-wrapper, .confirmation-page').length > 0; // Cart | Checkout | Order Summary
        var gProfilePlus = document.querySelectorAll('.workspace-wrapper, .profile-container-wrapper, .account-page.address-book-page, .payment-methods-wrapper, .create-account-wrapper, .reset-password-form').length > 0; // Workspace | Profile | Addresses | Payment Methods | Create Account | Reset Password

        eligibleGroups.forEach(function (eGroup) {
            pageSelectors += eGroup.value + '|';
        });

        if (gWebflow) {
            if (pageSelectors.indexOf('webflow') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gConfigurator) {
            if (pageSelectors.indexOf('configurator') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gCartCheckout) {
            if (pageSelectors.indexOf('cart-checkout') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gHomePlus) {
            if (pageSelectors.indexOf('home-plus') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gProductPath) {
            if (pageSelectors.indexOf('product-path') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gProfilePlus) {
            if (pageSelectors.indexOf('profile-plus') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gQuotesPricelist) {
            if (pageSelectors.indexOf('quotes-pricelist') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gOrders) {
            if (pageSelectors.indexOf('orders') > -1) { bannerClasses.addClass('bannerslide'); }
        } else if (gPortfoliosPlus) {
            if (pageSelectors.indexOf('portfolios-plus') > -1) { bannerClasses.addClass('bannerslide'); }
        } else {
            // Do not show banner on remaining pages
        }
    }

    closeBanner();
}

/**
 * Get and set configurations of temporary banner
 */
function temporaryBannerConfigurations() {
    var tempBannerConfigs = document.querySelectorAll('.tempBannerConfigs');

    tempBannerConfigs.forEach(function (bannerConfig) {
        var cssName = bannerConfig.name;
        var cssValue = bannerConfig.value;
        root.style.setProperty(
            cssName, cssValue
        );
    });
}

/**
 * Adjust content per banner and optionally display banner - Non-webflow pages
 */
function temporaryBanner() {
    temporaryBannerConfigurations();

    setTimeout(function () {
        var processedHeight = $('.header-banner').height();
        root.style.setProperty(
            '--hidden-banner', '-' + processedHeight + 'px'
        );
    }, 200);

    setTimeout(function () {
        root.style.setProperty('--banner-display', 'block');
        var processedHeaderHeight = $('.header.container').height() + $('.header-banner').height();
        root.style.setProperty(
            '--banner-maincontent-top', processedHeaderHeight + 'px'
        );
        root.style.setProperty(
            '--banner-modal-top', processedHeaderHeight + 'px'
        );
        root.style.setProperty(
            '--banner-refinements-top', processedHeaderHeight + 'px'
        );
        if (document.querySelectorAll('.page-clearheader').length > 0) {
            var processedBannerHeight = $('.header-banner').height() + 'px';
            root.style.setProperty(
                '--banner-clearheader-top', processedBannerHeight
            );
        }
    }, 250);

    setTimeout(function () {
        displayHeaderBanner();
    }, 1700);
}

/**
 * Adjust content per banner and optionally display banner - Webflow pages
 */
function temporaryBannerWebflow() {
    temporaryBannerConfigurations();

    setTimeout(function () {
        var processedHeight = $('.header-banner').height();
        root.style.setProperty(
            '--hidden-banner', '-' + processedHeight + 'px'
        );
        root.style.setProperty('--webflow_offset_nav', ($('header').height() - 2) + 'px');
    }, 300);

    setTimeout(function () {
        root.style.setProperty('--webflow_offset_nav', ($('.header.container').height() - 2) + 'px');
    }, 500);

    setTimeout(function () {
        root.style.setProperty('--banner-display', 'block');
        root.style.setProperty('--webflow_offset_nav', ($('.header.container').height() - 2) + 'px');
        var processedHeaderHeight = $('.header.container').height() + $('.header-banner').height();
        root.style.setProperty(
            '--banner-maincontent-top', processedHeaderHeight + 'px'
        );
        root.style.setProperty(
            '--banner-modal-top', processedHeaderHeight + 'px'
        );
        root.style.setProperty('--banner-webflow_offset_nav', (processedHeaderHeight - 2) + 'px');
        root.style.setProperty('--banner-webflow_offset_menu', processedHeaderHeight + 'px');
        root.style.setProperty('--banner-offset-text-middle-of-bg', (window.innerHeight - processedHeaderHeight) + 'px');
        root.style.setProperty('--banner-half-header', (processedHeaderHeight / 2) + 'px');
        root.style.setProperty('--this-height', $('.offset-partial-height-half-sticky-image').css('height'));
        root.style.setProperty('--half-this-height', ($('.offset-partial-height-half-sticky-image').height() / 2) + 'px');
        root.style.setProperty('--half-section-vh', (((window.innerHeight - processedHeaderHeight) / 2) + $('.webflow_offset_nav').height()) + 'px');
        root.style.setProperty('--banner-header-height', processedHeaderHeight + 'px');
        root.style.setProperty('--banner-webflow_offset', 'translateY(' + processedHeaderHeight + 'px)');
        root.style.setProperty('--banner-webflow_offset_afternav', 'translateY(' + (($('.header.container').height() + $('.header-banner').height()) - $('.webflow_offset_nav').height()) + 'px)');
        root.style.setProperty('--banner-webflow_offset_horizontal', processedHeaderHeight + 'px');
        root.style.setProperty('--banner-webflow_offset_horizontal_slides', (window.innerHeight - processedHeaderHeight) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky', (processedHeaderHeight * 0.6) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky_mobile_xs', (processedHeaderHeight + $('.webflow_offset_hamburger').height()) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky_mobile', (processedHeaderHeight + $('.webflow_offset_nav').height()) + 'px');
        displayHeaderBanner();
    }, 1950);
}

module.exports = {
    initTemporaryBanner: function () {
        var bannerCookieID = $('#temporary-banner').attr('class');
        if (!cookiemgr.getCookie(bannerCookieID)) {
            temporaryBanner();
        }
    },
    initTempBannerWebflow: function () {
        var bannerCookieID = $('#temporary-banner').attr('class');
        if (!cookiemgr.getCookie(bannerCookieID)) {
            temporaryBannerWebflow();
        }
    },
    initResizeBannerWebflow: function () {
        var processedHeaderHeight = $('.header.container').height() + $('.header-banner').height();
        $('.webflow_offset_nav, .webflow_offset_menu, .webflow_offset_afternav, .webflow_offset').addClass('resizing');
        root.style.setProperty(
            '--banner-maincontent-top', processedHeaderHeight + 'px'
        );
        root.style.setProperty(
            '--banner-modal-top', processedHeaderHeight + 'px'
        );
        var webflowHeaderHeight = $('.header.container').height() + $('.header-banner').height();
        root.style.setProperty('--banner-webflow_offset_nav', (webflowHeaderHeight - 2) + 'px');
        root.style.setProperty('--banner-webflow_offset_menu', webflowHeaderHeight + 'px');
        root.style.setProperty('--banner-offset-text-middle-of-bg', (window.innerHeight - webflowHeaderHeight) + 'px');
        root.style.setProperty('--this-height', $('.offset-partial-height-half-sticky-image').css('height'));
        root.style.setProperty('--banner-half-header', (webflowHeaderHeight / 2) + 'px');
        root.style.setProperty('--banner-header-height', webflowHeaderHeight + 'px');
        root.style.setProperty('--banner-webflow_offset', 'translateY(' + webflowHeaderHeight + 'px)');
        root.style.setProperty('--banner-webflow_offset_afternav', 'translateY(' + (($('.header.container').height() + $('.header-banner').height()) - $('.webflow_offset_nav').height()) + 'px)');
        root.style.setProperty('--banner-webflow_offset_horizontal_slides', (window.innerHeight - webflowHeaderHeight) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky', (webflowHeaderHeight * 0.6) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky_mobile_xs', (webflowHeaderHeight + $('.webflow_offset_hamburger').height()) + 'px');
        root.style.setProperty('--banner-webflow_offset_half_sticky_mobile', (webflowHeaderHeight + $('.webflow_offset_nav').height()) + 'px');
    }
};
